:root {
    /* Primary background color - buttons, links etc*/
    --primary: #0369F5;
    --primary-light: #4886FF;

    --secondary: orange;
    --secondary-light: #FBBE02;

    --tertiary: #E70113;
    --tertiary-light: #FF2F3F;

    --inactive:#AAAAAA;
    --highlight: lightgoldenrodyellow;
    /* content background - to be used for backgrounds, alternative backgrounds */
    --primary-bg-color: white;
    --primary-panel-color: #f3f3f3;
    --fill-light-gray:#f3f3f3;
    --primary-alt-color: #4886FF;

    --info-message-bg-color: grey;
    --warning-message-bg-color: orange;
    --danger-message-bg-color: #FF2F3F;
    // --success-message-bg-color: rgba(0,140,0, .5);
    --success-message-bg-color: rgba(0,140,0, 1);

    /* borders */
    --grey-border: 1px solid #eee;

    /* text color */
    --primary-heading-color:#000;
    --primary-text-color: #333;
    --primary-text-alt-color: #ffffff;
    --secondary-text-color: #555;


    --input-active:#f3f6fc;
    --fill-light:#e0e0e0;

    --fill-light-redish: #f5f0f0;

    --border-radius:1.75rem;
    --border-radius-input:0.25rem;

    --fill-light-low: rgba(0,0,0,.05);

    --bs-heading-color:--primary-heading-color;
}

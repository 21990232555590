.custom-datatable{
    table-layout: fixed;
    .sorting {
line-height: 24px;
        align-items: center;
        cursor: pointer;
    }
    .sorting span.material-icons{
        color: lightgray;
    }
    .sorting.active span.material-icons{
        color: blue;
    }
}

.table-filter-control{
    input{
        width: 250px;
    }
}

tbody.loading{
    pointer-events: none;
    opacity: .5;
}
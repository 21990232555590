.btn{
    font-size: .85rem;
    border-radius: var(--border-radius);
    padding: .5rem 1rem;
}

.bg-fill-light{
    background-color: var(--fill-light);
}
.bg-fill-light-redish{
    background-color: var(--fill-light-redish);
}

.bg-fill-primary{
    background-color: var(--primary);
}

.btn-classic.btn-xs, .btn-xs{
    padding: 0 .25rem !important;
    font-size: .7rem;
}
.btn-xs .material-icons{font-size: 1em !important;}

.btn-sm{
    padding: .25rem .75rem;
}
.btn-lg{
    padding: .75rem 2rem;
}
.btn-large {
    min-width: 150px;
}
.btn-primary{
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-secondary{
    background-color: var(--secondary);
    border-color: var(--secondary);
}

.btn-icon{
    background-color: transparent;
}
.btn-icon:hover{
    background-color: rgba(0,0,0,.1);
}

.btn-light-low{
    background-color: var(--fill-light-low) !important;
}


.btn .material-icons{
    font-size: 1rem;
}

.btn-classic{
    text-align: left;
    border-radius: var(--bs-btn-border-radius) !important;
    padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
}

.floating-action{
    position: fixed;
    z-index: 1010;
    right: 3rem;
    bottom: 3rem;

}

.float-top-right{
    z-index: 111;
    position: absolute;
    top:1rem;
    right:1rem;
}


.btn-clear{
    background-color: transparent;
    font-weight: 600;
    border: 0 none;
}
.btn-clear.primary{
    color: var(--primary);
}
.btn-clear.primary:hover{
    background-color: var(--primary);
    color: white;
}
.btn-clear.secondary{
    color: #999;
}
.btn-clear.secondary:hover{
    background-color: var(--secondary);
    color: white;
}

.icon {
    width: 100px;
    height: 100px;
    margin: 20px;
    display:inline-block;

}
.loader{
    display: flex;
    align-items: center;
    justify-content: center;
}

.downloading{
    position: fixed;
    z-index: 1100;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, .6);
    text-align: center;
    color: blue;
}

.downloading h1{margin-bottom: 10px; position: sticky; top: 150px;}

.freeContent{
    font-size: 15px;
    p {
        line-height: 175%;
    }
    h2{
        border-left: solid 5px var(--secondary);
        padding-left : 10px;
        margin-left: -15px;
        line-height: 120%;
        color:var(--primary-heading-color);
        font-size: 1.6rem;
    }

}

.header{
    box-shadow: 1px 1px 4px rgba(0,0,0,.1);
}


.paperDetails{
    padding-left:32px;
}
:root{
    --border-radius: 4px;
}

.card{
    border:0;
    color: var(--primary-text-color);
}
.card, .card-img, .card-img-top {
    border-radius: var(--border-radius);
}

.card-title.h5{
    font-size: 1.15rem;
    font-weight: 600;
    color: var(--primary-heading-color);;
}

.card-footer{
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
    background-color: transparent !important;

}
.card-header{
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
    background-color: transparent !important;
    border-bottom: 0 none !important;
}
/* label{
    color:rgba(0,0,0,.4);
}
.form-floating{
    .form-control, label{
    padding-left: 0;
    }   
}
.form-control{
    border:0;
    border-radius: 0;
    border-bottom: solid 1px #e6e6e6;
}
.form-control:focus{
    box-shadow: none;
}

.form-control + span::after{
    content: "";
    width: 100%;
    height: 2px;
    display: block;
    bottom: -1px;
    background-color: #001E80;
    transform: scaleX(0);
    -ms-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    position: absolute;
    z-index: 2
}

.form-control + span::after{
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    -ms-transition-timing-function: cubic-bezier(.4,0,.2,1);
    -webkit-transition-timing-function: cubic-bezier(.4,0,.2,1)
}

.form-control:focus + span::after {
    transform: scaleX(1);
    -ms-transform: scaleX(1);
    -webkit-transform: scaleX(1)
}
.form-check-input[type=checkbox]{
    border-radius: 2px;
    border-width: 2px;
    border-color: rgba(0,0,0,.54);
}
.form-check-input{
    width: 18px;
    height: 18px;
}
.form-check-input:focus{
    box-shadow: none;
}
.form-check label{
    cursor: pointer;
    color: #4d575d;
    line-height: 1.7rem;
}
.form-check [type="checkbox"]:checked{
    background-color: #4acc8e;
    border-color: #4acc8e;
}
.form-check-input:checked {
    background-color: #4acc8e;
    border-color: #4acc8e;
}

.input-group-text{
    background-color: transparent;
    border: 0 none;
    border-radius: 0;

    span{color: rgba(0,0,0,.54);}
}
 */

::placeholder{
    opacity: .5 !important;
}
.form-floating{
    .form-control, label{
    // padding-left: 0;
    }   
}
.form-control, .form-select{
    // border:0;
    // border-radius: .25rem .25rem 0 0;
    // border-bottom: solid 1px #939283;
    border-color:  #939283;
    background-color: transparent;
}
.form-control:focus, .form-select:focus{
    box-shadow: none;
    // color:var(--primary);
    background-color: var(--input-active);
}

.form-control + span::after, .form-select + span::after{
    content: "";
    width: 100%;
    height: 2px;
    display: block;
    bottom: -1px;
    background-color: var(--primary);
    transform: scaleX(0);
    -ms-transform: scaleX(0);
    -webkit-transform: scaleX(0);
    position: absolute;
    z-index: 2
}

.form-control + span::after, .form-select + span::after{
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    -ms-transition-timing-function: cubic-bezier(.4,0,.2,1);
    -webkit-transition-timing-function: cubic-bezier(.4,0,.2,1)
}

.form-control:focus + span::after, .form-select:focus + span::after {
    transform: scaleX(1);
    -ms-transform: scaleX(1);
    -webkit-transform: scaleX(1)
}
.form-check-input[type=checkbox]{
    border-radius: var(--border-radius);
    border-width: 2px;
    border-color: rgba(0,0,0,.54);
}
.form-check-input{
    width: 18px;
    height: 18px;
}
.form-check-input:focus{
    box-shadow: none;
}
.form-check label{
    cursor: pointer;
    color: #4d575d;
    line-height: 1.7rem;
}
.form-check [type="checkbox"]:checked{
    background-color: var(--primary-light);
    border-color: var(--primary-light);
}
.form-check-input:checked {
    background-color: var(--primary-light);
    border-color:var(--primary-light);
}

.input-group-text{
    background-color: transparent;
    border: 0 none;
    border-radius: 0;

    span{color: rgba(0,0,0,.54);}
}
.input-group-text-with-bg .input-group-text{
    background-color: #eee;
    border: var(--bs-border-width) solid #939283;
    border-radius: var(--bs-border-radius);

    span{color: rgba(0,0,0,.54);}
}

.form-floating>.form-control-plaintext~label::after, .form-floating>.form-control:focus~label::after, .form-floating>.form-control:not(:placeholder-shown)~label::after, .form-floating>.form-select~label::after{
    background-color: transparent;
}

.form-label{position:relative}

.cozy{

    .row{
        margin-left: calc(-.5 * .5rem);
        margin-right: calc(-.5 * .5rem);
    }
    .row>* {
        padding-left: calc(.5rem * .5);
        padding-right: calc(.5rem * .5);
    }
    label{
        font-size: .7rem;
    }
    .form-control, .form-select{
        font-size: .7rem;
        padding: .175rem .375rem;
    }

    .btn{
        padding: 2px 4px !important;
        font-size: .82rem;
    }
}

.required{
    label:after{
        content: "*" !important;
        position: absolute;
        color: red;
        left: calc(100% + 5px) !important;
    }
}
.sidebar-layout{
    display: flex;
}

.sidebarHandel, .sidebarHandel:hover{
    color: #fff;
}
.sidebarHandel span{
    vertical-align: middle;
}

.sidebar{
    width: 4.5rem;
    transition: width linear .3s;
    overflow-y: auto;
    overflow-x: hidden;
    white-space: nowrap;
    height: 100vh;
    background: #09497D;
}

.sidebar-nav .title, .logo{
    display: none;    
}

.sidebar.active {width: 274px;}

.sidebar.active .sidebar-nav .title, .sidebar.active .logo{
    display: inline;    
}

.sidebar-nav a{
    // color:#c8c8c8; 
    color: white;
    background-color: transparent;
    text-decoration: none; 
    padding: .5rem 1rem; 
    // font-size: 15px;
    display: block;
    border-radius: .25rem;

}
.sidebar-nav a:hover{
    background-color: white;
    color:black;
}
.sidebar-nav a.active{
    background-color: #CBDCE9;
    color:black;
}
.sidebar-nav a span{
    vertical-align: middle;
    min-width: 3rem;
    padding-right: .5rem;
}
.sidebar-nav{
    list-style: none;
    margin: 0;
    padding: 0;
}

.sideLink{
    color: #fff;
}
.sideLink:hover{cursor: pointer;}

.sidebar-nav .subNavDropdown li a {
    display: inline-block;
    padding-top: 6px;
    padding-bottom: 6px;

}
.sidebar-nav .subNavDropdown li a.active{
    background-color: rgba(255,255,255, 1);
    border-radius: .25rem;
}

.subNavDropdown{
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: all .25s ease;
    padding-left: 3rem;
}

.subNavDropdown li{margin-top: .3rem;}

.sidebar.active {
    .subNav > a.dropActive + .subNavDropdown, .subNav > a.active + .subNavDropdown{
        height: 100%;
    }
}


.sidebar-nav a span.material-icons{
    font-size: 1.4rem;
    min-width: 2rem !important;
}